import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top"; // import instrumentA from "./components/instrument-a";

import instrumentB from "./components/instrument-b";
import instrumentC from "./components/instrument-c";
import instrumentD from "./components/instrument-d";
import instrumentE from "./components/instrument-e";
import instrumentF from "./components/instrument-f";
import { mapMutations } from "vuex"; // import CommonFooter1 from "@/components/common-footer1";
// import {mapMutations} from 'vuex'

export default {
  metaInfo: {
    title: "工器具智能管理方案",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "RFID仓储管理方案,RFID手持设备,RFID智能工具柜,智能工具箱,RFID智能工具管理系统方案,常达,"
    }, {
      name: "description",
      content: "利用RFID技术，把贴有RFID标签的工具放入特殊定制并加装RFID读写器的工具车、工具柜或工具箱中，实现工具借用、归还、查找、定位、盘点。"
    }]
  },
  data: function data() {
    return {
      swiperTitle: "系统实现的主要功能。",
      swiperList: [{
        t: "工器具录入",
        c: "可以录入工器具信息。新增工器具时要对工器具进行二维码编码并输入基本信息和维护信息。",
        icon: require("@/assets/instrument/swiper/1.png"),
        icon2: require("@/assets/instrument/swiper/1c.png")
      }, {
        t: "设备信息查看",
        c: "支持RFID扫描技术，每个工器具都分配一个唯一的标识，通过扫描标签可查看名称、厂家、规格型号、技术参数、出厂日期、投运日期、更换部件、检验日期、有效期等相关信息。",
        icon: require("@/assets/instrument/swiper/2.png"),
        icon2: require("@/assets/instrument/swiper/2c.png")
      }, {
        t: "反馈设备状态",
        c: "巡检人员发现设备缺陷时，可以使用专用的APP扫描设备二维码进入设备缺陷登记链接，现场填写缺陷记录，并保存入数据库之中，实现设备缺陷管理自动化。",
        icon: require("@/assets/instrument/swiper/3.png"),
        icon2: require("@/assets/instrument/swiper/3c.png")
      }, {
        t: "工器具报废",
        c: "根据工器具试验情况或使用寿命到期，对工器具进行报废并归档，并形成报废工器具的试验报告。",
        icon: require("@/assets/instrument/swiper/4.png"),
        icon2: require("@/assets/instrument/swiper/4c.png")
      }, {
        t: "预警提示",
        c: "当存在超期或者快到期试验（可设定时间）工器具，用户登录时会给出醒目的提示，手机将会受到从后台发来的短信息通知，以便及时发现。",
        icon: require("@/assets/instrument/swiper/5.png"),
        icon2: require("@/assets/instrument/swiper/5c.png")
      }, {
        t: "台账管理",
        c: "形成台账和分类查询报表。管理人员能够以最快的速度知道工器具超期情况，便于检验安排。并根据每年的报废情况生成第二年的采购计划，对其采购实行精准有据可依的管理。",
        icon: require("@/assets/instrument/swiper/6.png"),
        icon2: require("@/assets/instrument/swiper/6c.png")
      }],
      recommend: {
        title: "工器具管理产品推荐",
        list: [{
          image: require("@/assets/instrument/recommend/1.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=1"
        }, {
          image: require("@/assets/instrument/recommend/2.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/instrument/recommend/3.png"),
          to: "/product?name=工器具设备&current=2"
        }, {
          image: require("@/assets/instrument/recommend/4.png"),
          to: "/product?name=智能工具箱"
        }, {
          image: require("@/assets/instrument/recommend/5.png"),
          to: "product?name=智能工具车"
        }, {
          image: require("@/assets/instrument/recommend/6.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=3"
        }, {
          image: require("@/assets/instrument/recommend/7.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=4"
        }]
      }
    };
  },
  components: {
    // CommonFooter1,
    top: top,
    // instrumentA,
    instrumentB: instrumentB,
    instrumentC: instrumentC,
    instrumentD: instrumentD,
    instrumentE: instrumentE,
    instrumentF: instrumentF
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};