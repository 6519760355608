export default {
  data: function data() {
    return {
      list: [{
        icon: require("@/assets/instrument/f/icon1.png"),
        t: "工器具录入",
        c: "可以录入工器具信息。新增工器具时要对工器具进行二维码编码并输入基本信息和维护信息。"
      }, {
        icon: require("@/assets/instrument/f/icon2.png"),
        t: "设备信息查看",
        c: "支持RFID扫描技术，每个工器具都分配一个唯一的标识，通过扫描标签可查看名称、厂家、规格型号、技术参数、出厂日期、投运日期、更换部件、检验日期、有效期等相关信息。"
      }, {
        icon: require("@/assets/instrument/f/icon3.png"),
        t: "反馈设备状态",
        c: "巡检人员发现设备缺陷时，可以使用专用的APP扫描设备二维码进入设备缺陷登记链接，现场填写缺陷记录，并保存入数据库之中，实现设备缺陷管理自动化。"
      }, {
        icon: require("@/assets/instrument/f/icon4.png"),
        t: "工器具报废",
        c: "根据工器具试验情况或使用寿命到期，对工器具进行报废并归档，并形成报废工器具的试验报告。"
      }, {
        icon: require("@/assets/instrument/f/icon5.png"),
        t: "预警提示",
        c: "当存在超期或者快到期试验（可设定时间）工器具，用户登录时会给出醒目的提示，手机将会受到从后台发来的短信息通知，以便及时发现。"
      }, {
        icon: require("@/assets/instrument/f/icon6.png"),
        t: "台账管理",
        c: "形成台账和分类查询报表。管理人员能够以最快的速度知道工器具超期情况，便于检验安排。并根据每年的报废情况生成第二年的采购计划，对其采购实行精准有据可依的管理。"
      }]
    };
  }
};