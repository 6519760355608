export default {
  data: function data() {
    return {
      list: [{
        t: "信息化",
        c: "领用、归还、盘点智能化无需人工处理，提高工作效率",
        img: require("@/assets/instrument/d/icon1.png")
      }, {
        t: "自动化",
        c: "智能工器具柜自动盘点实时掌握最新情况",
        img: require("@/assets/instrument/d/icon2.png")
      }, {
        t: "高效化",
        c: "盘存速率快，盘存标签数量300枚以上信息时间最快可达5秒，无误读",
        img: require("@/assets/instrument/d/icon3.png")
      }, {
        t: "智能化",
        c: "定位精准，可精准定位到工具柜的柜、层",
        img: require("@/assets/instrument/d/icon4.png")
      }, {
        t: "安全化",
        c: "工器具借出超时或使用年限语音提示报警",
        img: require("@/assets/instrument/d/icon5.png")
      }]
    };
  }
};