import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-99140dba"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "instrument"
};
var _hoisted_2 = {
  class: "instrument-b-bg"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_instrument_b = _resolveComponent("instrument-b");

  var _component_instrument_e = _resolveComponent("instrument-e");

  var _component_instrument_f = _resolveComponent("instrument-f");

  var _component_instrument_c = _resolveComponent("instrument-c");

  var _component_instrument_d = _resolveComponent("instrument-d");

  var _component_common_recommend = _resolveComponent("common-recommend");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_top), _createElementVNode("div", _hoisted_2, [_createVNode(_component_instrument_b)]), _createVNode(_component_instrument_e), _createVNode(_component_instrument_f), _createVNode(_component_instrument_c), _createVNode(_component_instrument_d), _createVNode(_component_common_recommend, {
    title: $data.recommend.title,
    list: $data.recommend.list
  }, null, 8, ["title", "list"]), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '0 0 .4rem'
    })
  }, null, 4), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top)]);
}