export default {
  data: function data() {
    return {
      current: 0,
      nav: [{
        title: "登录系统",
        list: [{
          t: "登录方式",
          image: require("@/assets/instrument/e/login/1.png")
        }, {
          t: "首页功能展示",
          image: require("@/assets/instrument/e/login/2.png")
        }]
      }, {
        title: "工具相关",
        list: [{
          t: "标签绑定",
          image: require("@/assets/instrument/e/tool/1.png")
        }, {
          t: "工具生命周期",
          image: require("@/assets/instrument/e/tool/2.png")
        }, {
          t: "仓库管理",
          image: require("@/assets/instrument/e/tool/3.png")
        }]
      }, {
        title: "日常管理",
        list: [{
          t: "工具借用",
          image: require("@/assets/instrument/e/daily/1.png")
        }, {
          t: "工具维护",
          image: require("@/assets/instrument/e/daily/2.png")
        }, {
          t: "工具盘点",
          image: require("@/assets/instrument/e/daily/3.png")
        }]
      }, {
        title: "数据报表",
        list: [{
          t: "工具概况分析",
          image: require("@/assets/instrument/e/data/1.png")
        }, {
          t: "工具闲置分析",
          image: require("@/assets/instrument/e/data/2.png")
        }, {
          t: "维修分析",
          image: require("@/assets/instrument/e/data/3.png")
        }]
      }, {
        title: "系统设置",
        list: [{
          t: "工厂管理",
          image: require("@/assets/instrument/e/system/1.png")
        }, {
          t: "系统配置",
          image: require("@/assets/instrument/e/system/2.png")
        }, {
          t: "字典管理",
          image: require("@/assets/instrument/e/system/3.png")
        }]
      }]
    };
  },
  computed: {
    list: function list() {
      var current = this.current,
          nav = this.nav;
      return nav[current].list || [];
    }
  }
};