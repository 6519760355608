export default {
  data: function data() {
    return {
      current: 0,
      list: [{
        img: require("@/assets/instrument/b/1.png"),
        t: "RFID智能工具管理系统",
        c: "利用RFID技术，把贴有RFID标签的工具放入特殊定制并加装RFID读写器的工具车、工具柜或工具箱中，实现工具借用、归还、查找、定位、盘点。不同的硬件设备方案适用于不同的作业环境！"
      }, {
        img: require("@/assets/instrument/b/2.png"),
        t: "RFID智能工具车",
        c: "RFID智能工具车采用RFID技术，由自主研发控制系统及多个定位天线单元组成，可以实现工具定位到层的精准控制，大幅提高工器具管理的准确性和高效性，有效预防和减少工器具丢失和被盗、简化工器具进出库的登记流程、避免库存缺失或管理疏忽等，可以满足航空业各类工具储存需求的智能设备，实现了企业对工具的信息化管理及航空业FOD的精细化控制。"
      }, {
        img: require("@/assets/instrument/b/3.png"),
        t: "RFID智能工具柜",
        c: "超高频RFID高铁工器具管理柜，主要用于轨道交通的维修工具、测量仪器、仪表等器具的管理。通过无源RFID标签对工具进行唯一标识，工具领用或者归还时，通过APP软件进行权限的管理并开门，记录使用责任人，关门后，柜体实现自动盘点，记录领用工具的数量及种类。"
      }, {
        img: require("@/assets/instrument/b/4.png"),
        t: "RFID智能工具箱",
        c: "RFID智能工具箱，主要用于工具的现场管理。通过无源RFID标签对工具进行唯一标识，工具在现场归还时，通过RFID自动盘点，即可对缺失工具进行报警提醒，避免工具出现遗漏现场所引发的安全事故。"
      }]
    };
  },
  methods: {
    f: function f(v) {
      return v < 10 ? "0" + v : v;
    }
  }
};